<template>
  <b-modal
    id="update-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        {{ blackDataItem.id ? '編輯黑名單' : '新增黑名單' }}
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- 黑名單帳號 -->
        <validation-provider
          #default="{ errors }"
          name="黑名單帳號"
        >
          <b-form-group label-for="name">
            <template #label>
              <label class="mb-0">
                黑名單帳號
                <!-- <span class="text-danger">*</span> -->
              </label>
            </template>
            <b-form-input
              id="account"
              v-model="blackData.account"
              :state="errors.length > 0 ? false : null"
              trim
              :placeholder="blackDataItem.account ? blackDataItem.account : '請輸入黑名單帳號'"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- 黑名單IP位置 -->
        <validation-provider
          #default="{ errors }"
          name="IP位置"
        >
          <b-form-group
            label-for="ip"
            class="mb-50"
          >
            <template #label>
              <label class="mb-0">
                IP位置
                <!-- <span class="text-danger">*</span> -->
              </label>
            </template>
            <b-form-input
              id="name"
              v-model="blackData.ip"
              :state="errors.length > 0 ? false : null"
              trim
              :placeholder="blackDataItem.ip ? blackDataItem.ip : '請輸入IP位置'"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <div class="mb-1">
          <small>
            <!-- class="text-muted" -->
            <span>
              <b-img
                :src="$store.state.app.themeImages.infoImg"
                height="22"
                width="22"
                class="cursor-pointer"
              />
            </span>
            帳號和IP位置至少填寫一項
          </small>
        </div>

        <div>
          <label>原因說明</label>
          <b-form-textarea
            v-model="blackData.reason"
            :placeholder="blackDataItem.reason ? blackDataItem.reason : '請輸入原因說明'"
            :state="blackData.reason.length <= maxChar ? null : false"
            rows="5"
          />
          <small
            class="textarea-counter-value float-right"
            :class="blackData.reason.length > maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ blackData.reason.length }}</span> / {{ maxChar }}
          </small>
        </div>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
// API
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

// UI
import {
  BForm, VBToggle, BButton, BSpinner, BFormInvalidFeedback, BFormGroup, BFormInput, VBTooltip,
  BFormTextarea, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// Component
import formValidation from '@core/comp-functions/forms/form-validation'
import { useBlackList, useBlackSetting } from '../useBlack'
import { useAlert } from '@/libs/mixins/index'

export default {
  components: {
    BImg,
    BForm,
    // BFormGroup,
    BButton,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BFormTextarea,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    blackDataItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      missingFields: [],
      maxChar: 255,
    }
  },
  methods: {
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetBlackData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('update-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      // 判斷必填欄位是否填寫
      // const requiredFields = {
      //   account: '黑名單帳號',
      // }

      // this.missingFields = []

      // for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
      //   if (this.blackData[Object.keys(requiredFields)[i]] === null || this.blackData[Object.keys(requiredFields)[i]] === '') {
      //     this.missingFields.push({
      //       lable: Object.values(requiredFields)[i],
      //       value: Object.keys(requiredFields)[i],
      //     })
      //   }
      // }

      if (this.blackData.reason.length > this.maxChar) {
        this.useAlertToast(false, '原因說明字數超過上限')
        return
      }

      // if (this.missingFields.length > 0) {
      //   this.useAlertToast(false, `${this.missingFields[0].lable}不得為空`)
      //   return
      // }

      this.isBusy = true

      if (this.blackDataItem.id) {
        this.setBlackUpdate({
          black_id: this.blackDataItem.id,
          data: {
            ...this.blackData,
          },
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('refetch-data')
            this.resetBlackData()
            this.$nextTick(() => {
              this.$bvModal.hide('update-modal')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      } else {
        this.setBlackCreate({
          ...this.blackData,
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('refetch-data')
            this.resetBlackData()
            this.$nextTick(() => {
              this.$bvModal.hide('update-modal')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      }
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        ...this.syncObject(this.blankBlackData, this.blackDataItem),
      }
      this.blackData = resolveData
      this.$bvModal.show('update-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      isBusy,
      setBlackCreate,
      setBlackUpdate,
    } = useBlackList()

    const {
      stateOptions,
      getMetaListData,
      departmentsOptions,
      syncObject,
    } = useBlackSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankBlackData = {
      account: null,
      ip: null,
      reason: '',
    }

    const blackData = ref(JSON.parse(JSON.stringify(blankBlackData)))

    const resetBlackData = () => {
      blackData.value = JSON.parse(JSON.stringify(blankBlackData))
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetBlackData)

    return {
      isBusy,

      blackData,
      stateOptions,
      departmentsOptions,
      blankBlackData,
      setBlackCreate,
      setBlackUpdate,
      getMetaListData,

      resetBlackData,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}

.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}

.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}
</style>
